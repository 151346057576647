<template>
  <Layout mainClasses="px-5 bg-app-gray">
    <h1 class="text-left mt-5 font-body" style="font-size: 36px">
      <!-- {{ $t('link.widget.sidebar.reports') }} -->
      Callback requests
    </h1>

    <ReportsData url="callback" />
  </Layout>
</template>

<script>
import { useRoute } from "vue-router";
import { computed, inject } from "vue";

import Layout from "../../../components/home/Layout/Index";
import ReportsData from "./components/ReportsData.vue";

export default {
  name: "Stats",

  components: {
    Layout,
    ReportsData,
  },

  setup() {
    const t = inject("t");
    const route = useRoute();
    const tab = computed(() => route.name);

    const getHeading = (tab) => {
      switch (tab) {
        case "calls":
          return t("link.widget.sidebar.calls");

        case "call-request":
          return t("link.widget.sidebar.callRequests");

        case "video-call":
          return t("link.widget.sidebar.videoCalls");

        case "emails":
          return t("link.widget.sidebar.emails");

        case "messages":
          return t("link.widget.sidebar.messages");

        default:
          return t("link.widget.sidebar.calls");
      }
    };

    return {
      tab,
      getHeading,
    };
  },
};
</script>

<style>
.dp-custom-input {
  border: none;
  width: 200px;
}
</style>
<style lang="scss" scoped>
.filters {
  display: grid;
  grid-template-columns: 95% 5%;
  align-items: center;
}
</style>
