<template>
  <tbody class="stats-table-body" v-if="data.length">
    <tr v-for="stats in data" :key="stats.id">
      <td :title="stats.widget">{{ slice(stats.widget, 20) }}</td>
      <OfficeAndDepartment :stats="stats" />
      <td>{{ stats.fullname }}</td>
      <td>{{ stats.email }}</td>
      <td :title="stats.subject">{{ stats.subject }}</td>
      <td>{{ moment(stats.senttime).format('LL') }}</td>
    </tr>
  </tbody>
</template>

<script setup>
import moment from 'moment'
import useHelpers from '@/composables/useHelpers'
import OfficeAndDepartment from './OfficeAndDepartment'

defineProps({
  data: {
    type: [Array, Object],
    required: true
  }
})

const { slice } = useHelpers()
</script>

<style lang="scss" scoped>
td {
  vertical-align: top;
}
</style>
