import lodash from "lodash";
import store from "@/store/index";
import { computed } from "vue";
import moment from "moment";
import { GET_STATS, SET_STATS } from "../../store/types";

export default function useStats() {
  const loading = computed(() => {
    const { id, value } = store.getters["stats/loading"];
    return id == "stats" && value;
  });

  const getStats = lodash.throttle((data) => {
    let { url } = data;
    const formData = {
      filters: {
        widget_id: null,
        office_id: null,
        department_id: null,
        startTime: moment(data.filters.startTime).format("YYYY-MM-DD HH:mm:ss"),
        endTime: moment(data.filters.endTime).format("YYYY-MM-DD HH:mm:ss"),
      },
      url: url,
    };

    if (data?.filters?.activeWidget?.id !== 0)
      formData.filters.widget_id = data.filters.activeWidget.id;
    if (data?.filters?.activeDepartment?.id !== 0)
      formData.filters.department_id = data.filters.activeDepartment.id;
    if (data?.filters?.activeContact?.id !== 0)
      formData.filters.office_id = data.filters.activeContact.id;

    store.dispatch(`stats/${GET_STATS}`, formData);
  }, 2000);

  return {
    getStats,
    loading,
  };
}
