<template>
  <PopUP
    @toggle="toggleModal('messageModal')"
    :open="isOpenModal('messageModal')"
  >
    <template #header> Message </template>
    <template #content>
      <div v-if="data && data?.parts?.length">
        <div v-for="(item, index) in data.parts" :key="index">
          <p v-if="item.type === 'text/plain'" class="p-3">
            {{ item.message }}
          </p>
          <!-- Image -->
          <div
            v-if="item.type === 'image/png' || item.type === 'image/jpeg'"
            class="flex p-3"
          >
            <img
              :src="item.file"
              :alt="item.name"
              v-if="item.type === 'image/png' || item.type === 'image/jpeg'"
              class="w-56 h-40"
            />
          </div>
          <!-- Video -->
          <div v-if="item.type === 'video/mp4'" class="flex p-3">
            <video width="320" height="240" controls>
              <source
                :src="item.file"
                type="video/mp4"
                v-if="item.type === 'video/mp4'"
              />
              <source
                :src="item.file"
                type="video/ogg"
                v-if="item.type === 'video/ogg'"
              />
            </video>
          </div>
          <!-- Document -->
          <div
            v-if="item.type.includes('application')"
            class="p-3"
          >
          <p class="mb-2">{{ t("label.modal.messages.name") }}
            <span class="font-bold">{{ item.name }} </span>
            </p>
          <p class="mb-4">{{ t("label.modal.messages.size") }}
            <span class="font-bold"> {{ item.size }}</span>
          </p>
            <a :href="item.file" download @click.prevent="downloadItem(item)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="w-6 h-6 cursor-pointer"
              >
                <path
                  d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </template>
  </PopUP>
</template>

<script setup>
import { inject } from "vue";
import PopUP from "@/shared/PopUP";
import useModal from "@/composables/useModal";
import useToaster from "@/composables/useToaster";
import axios from "axios";

defineProps({
  data: {
    type: [Array, Object],
    required: true,
  },
});

const { toggleModal, isOpenModal } = useModal();
const { trigger } = useToaster();
const t = inject("t");

const downloadItem = ({ file, name }) => {
  axios
    .get(file, { responseType: "blob" })
    .then((response) => {
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = name;
      link.click();
      URL.revokeObjectURL(link.href);
      toggleModal("messageModal", true);
    })
    .catch(() => {
      toggleModal("messageModal", true);
      trigger({
        message: t("notify.modals.error_download"),
        type: "danger",
      });
    });
};
</script>
