<template>
  <!-- <messageModal :data="JSON.parse(stats.message)" /> -->
  <messageModal :data="modalData" />
  <tbody class="stats-table-body" v-if="data.length">
    <tr v-for="stats in data" :key="stats.id">
      <td :title="stats.widget">{{ slice(stats.widget, 20) }}</td>
      <OfficeAndDepartment :stats="stats" />
      <td>
        {{ JSON.parse(stats.sender).name }}
        <br />{{ JSON.parse(stats.sender).phone }} <br />{{
          JSON.parse(stats.sender).email
        }}
      </td>
      <td>{{ moment(stats.senttime).format("LL") }}</td>
      <td :title="stats.widget?.name" class="text-center align-middle messages-td">
        <!-- <div class="flex items-center justify-center h-full"> -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 576 512"
            class="cursor-pointer"
            fill="#2c3e50"
            @click="openMessageModal(stats.message)"
          >
            <path
              d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
            />
          </svg>
        <!-- </div> -->
      </td>
      <!-- <td :title="stats.widget?.name">{{ stats.message }} </td> -->
      <!-- <td>{{ moment(stats.sentime).format("LL") }}</td> -->
      <!-- <td>
        <a class="cursor-pointer" > Show message</a>
       
      </td> -->
    </tr>
  </tbody>
</template>

<script setup>
import { ref } from "vue";
import moment from "moment";
import useHelpers from "@/composables/useHelpers";
import useModal from "@/composables/useModal";
import OfficeAndDepartment from "./OfficeAndDepartment";
import messageModal from "./MessageModal";
import { defineComponent } from "vue";

defineProps({
  data: {
    type: [Array, Object],
    required: true,
  },
});
const { toggleModal } = useModal();
const modalData = ref(null);

const openMessageModal = (message) => {
  modalData.value = JSON.parse(message);
  toggleModal("messageModal", true);
};

const { slice } = useHelpers();
</script>

<style lang="scss" scoped>
td {
  vertical-align: top;
}
td.messages-td{
  text-align: center;
  vertical-align: middle;
}
td.messages-td svg{
  display: inline-block;
}
</style>
