<template>
  <td :title="findOffice(stats.office_id)">
    {{ findOffice(stats.office_id) }}
  </td>
  <td :title="findDepartment(stats.department)" class="font-bold">
    {{ stats.department }}
    <!-- {{ findDepartment(stats.department_id) }} -->
  </td>
</template>

<script setup>
import { inject } from 'vue'
import useHelpers from '@/composables/useHelpers'

defineProps({
  stats: {
    required: true,
    type: [Array, Object]
  }
})

const { slice } = useHelpers()

const findOffice = inject('findOffice')
const findDepartment = inject('findDepartment')
</script>

<style lang="scss" scoped>
td {
  vertical-align: top;
}
</style>
