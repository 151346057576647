<template>
  <div class="stats-table mb-8">
    <template v-if="loading">
      <!-- <TableLoader /> -->
    </template>

    <div v-else>
      <table class="table-fixed border-collapse w-full mb-3">
        <thead
          class="text-app-purple text-xl font-inter font-normal border-0 border-b-2 border-app-purple p-2 pb-3 text-left"
        >
          <tr>
            <td v-for="col in columns" :key="col">
              {{ $t(`text.widget.pages.stats.${col}`) }}
            </td>
          </tr>
        </thead>

        <template v-if="['calls'].includes(tab)">
          <Calls :data="pageData" />
        </template>

        <template v-if="['videoCalls'].includes(tab)">
          <Videos :data="pageData" />
        </template>

        <template v-if="['callRequests'].includes(tab)">
          <Callbacks :data="pageData" />
        </template>

        <template v-if="tab == 'emails'">
          <Emails :data="pageData" />
        </template>

        <template v-if="tab == 'messages'">
          <Messages :data="pageData" />
        </template>
      </table>

      <div class="float-right flex align-items-center gap-2 py-4">
        <div class="text-gray-600 text-sm">
          <span v-show="pages > 1"
            >Page {{ meta.page }} of {{ pages }} of
          </span>
          <span>{{ meta.total }} Records </span>
        </div>

        <v-pagination
          v-if="allPages > 1"
          v-model="meta.page"
          :pages="allPages"
          :range-size="1"
          active-color="#DCEDFF"
          @update:modelValue="changePage"
        />
      </div>

      <div class="float-none clear-both mb-3"></div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import VPagination from '@hennge/vue3-pagination'
import Messages from './Messages'
import Callbacks from './Callbacks'
import Videos from './Video'
import Calls from './Calls'
import Emails from './Emails'

const props = defineProps({
  data: {
    type: [Array, Object],
    required: true
  },
  meta: {
    type: [Object],
    required: true
  },
  columns: {
    required: true,
    type: Array
  },
  tab: {
    required: true,
    type: String
  },
  loading: Boolean
})

const currentPage = ref(1);
const perPage = ref(11);

defineEmits(['fetch'])

const pages = computed(() => {
  return 'total' in props.meta
    ? Math.ceil(props.meta.total / props.meta.perPage)
    : 0
});

const allPages = computed(() => props.data.length < perPage.value ? 1 : Math.ceil(props.data.length / perPage.value));

const pageData = computed(() => {
  const start = (currentPage.value - 1) * perPage.value;
  const end = start + perPage.value;
  return props.data.slice(start, end);
});

const changePage = (page) => {
  currentPage.value = page;
}

</script>

<style lang="scss" scoped>
.sortable {
  cursor: pointer;
  position: relative;
  span {
    &::after {
      content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"><path d="M12 3.202l3.839 4.798h-7.678l3.839-4.798zm0-3.202l-8 10h16l-8-10zm3.839 16l-3.839 4.798-3.839-4.798h7.678zm4.161-2h-16l8 10 8-10z"/></svg>');
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }
}
</style>
