<template>
  <div>
    <div class="my-4 filters">
      <Filters
        :show="showFilters"
        :tab="tab"
        class="bg-white px-2 p-1 mr-4"
        :class="{ 'p-2.5': ['messages', 'emails'].includes(tab) }"
      />

      <div
        class="cursor-pointer hover:opacity-90 justify-self-end bg-white p-2"
      >
        <Icon
          name="filters-square"
          @click="toggleFilters(true)"
          v-if="!showFilters"
          width="30"
          height="30"
        />

        <Icon
          name="times-square"
          @click="toggleFilters(true)"
          v-else
          width="30"
          height="30"
        />
      </div>
    </div>

    <Resource
      :columns="columns"
      :data="stats"
      :tab="tab"
      :meta="meta"
      :loading="loading"
      @fetch="fetchMore($event)"
      v-if="stats.length"
    />

    <div v-if="!stats || stats.length === 0">
      <table class="table-fixed border-collapse w-full mb-3">
        <div>There is no records</div>
      </table>
    </div>
  </div>
</template>

<script>
import lodash, { stubFalse } from 'lodash'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { ref, computed, onMounted, watch, provide, inject } from 'vue'

import Card from '@/modules/widget/components/home/Reports/Card'
import Filters from '../../../../components/home/Reports/Filters/Index'
import Resource from '../../Resource'
import useFilters from '@/modules/widget/composables/Reports/useFilters'
import useStats from '@/modules/widget/composables/Reports/useStats'
import useWidgetConfig from '@/modules/widget/composables/useWidgetConfig'

export default {
  name: 'Stats',

  components: {
    Card,
    Filters,
    Resource
  },

  props: {
    url: {
      type: String,
      required: true
    }
  },

  setup (props) {
    const t = inject('t')
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const showFilters = ref(false)

    const {
      widgets,
      offices,
      departments,
      fullnames,
      emails,
      messageSenders,
      findOffice,
      findDepartment,
      filters,
      setActiveResourceFilter,
      clearAllFilters
    } = useFilters()

    provide('findOffice', findOffice)
    provide('findDepartment', findDepartment)

    const { getStats } = useStats()
    const { getContacts } = useWidgetConfig()

    const getHeading = tab => {
      switch (tab) {
        case 'calls':
          return t('link.widget.sidebar.calls')

        case 'call-requests':
          return t('link.widget.sidebar.callRequests')

        case 'video-calls':
          return t('link.widget.sidebar.videoCalls')

        case 'emails':
          return t('link.widget.sidebar.emails')

        case 'messages':
          return t('link.widget.sidebar.messages')

        default:
          return t('link.widget.sidebar.calls')
      }
    }

    const isNotTab = computed(() => {
      return (
        tab.value == null ||
        tab.value == '' ||
        ![
          'calls',
          'video-calls',
          'messages',
          'call-requests',
          'emails'
        ].includes(tab.value)
      )
    })

    const stats = computed(
      () => store.getters['stats/stats'] || []
      // () => store.getters["stats/stats"]?.resource?.data || []
    )

    const isMounted = computed(() => store.getters['stats/isMounted'] || false)

    const meta = computed(() => {
      const data = store.getters['stats/stats']?.resource
      if (!data) return {}
      return {
        page: data.current_page,
        from: data.from,
        lastPage: data.last_page,
        perPage: data.per_page,
        to: data.to,
        total: data.total
      }
    })

    const loading = computed(() => {
      const { id, value } = store.getters['stats/loading']
      return id == 'stats' && value
    })

    const tab = computed(() => route.name)
    // const tab = computed(() => route.query.tab);

    const columns = computed(() => {
      let cols = []

      if (tab.value == 'calls' || tab.value == 'videoCalls') {
        cols = [
          'widget',
          'office',
          'department',
          'location',
          'start',
          'duration'
        ]
      }
      if (tab.value == 'callRequests') {
        cols = [
          'widget',
          'office',
          'department',
          'called-number',
          'start',
          'duration',
          'status'
        ]
      }

      // if (tab.value == "call-requests") {
      //   cols = [
      //     "widget",
      //     "office",
      //     "department",
      //     "ipaddress",
      //     "start",
      //     "duration",
      //     "called-number",
      //     "status",
      //   ];
      // }

      if (tab.value == 'messages') {
        cols = [
          'widget',
          'office',
          'department',
          'sender',
          'senttime',
          'message'
        ]
      }

      if (tab.value == 'emails') {
        cols = [
          'widget',
          'office',
          'department',
          'fullname',
          'email',
          'subject',
          'senttime'
        ]
      }

      return cols
    })

    const reportsData = [
      {
        title: 'calls',
        meta: {
          released: 112,
          missed: 12
        },
        icon: 'phone-ringing',
        tab: 'calls'
      },
      {
        title: 'video calls',
        meta: {
          released: 112,
          missed: 12
        },
        icon: 'video',
        tab: 'video-calls'
      },
      {
        title: 'emails',
        meta: {
          released: 112,
          missed: 12
        },
        icon: 'email',
        tab: 'emails'
      },
      {
        title: 'call requests',
        meta: {
          released: 112,
          missed: 12
        },
        icon: 'phone-alt',
        tab: 'call-requests'
      },
      {
        title: 'messages',
        meta: {
          released: 112,
          missed: 12
        },
        icon: 'comment',
        tab: 'messages'
      }
    ]

    const dateFilter = resource => {
      const { data, start, end } = resource
      if (start == undefined || end == undefined) return data

      return data.filter(data => {
        return (
          new Date(data.callstart).valueOf() >= start &&
          new Date(data.callstop).valueOf() <= end
        )
      })
    }

    const toggleFilters = (show = true) => {
      show == true
        ? (showFilters.value = !showFilters.value)
        : (showFilters.value = false)
    }

    const clearFilters = (show = true) => {
      show == true
        ? (showFilters.value = !showFilters.value)
        : (showFilters.value = false)

      clearAllFilters()
    }

    const fetchMore = page => {
      router.replace({
        name: 'report-stats',
        query: {
          tab: tab.value,
          page: page ?? 1
        }
      })

      getStatsData({
        resource: route.query.tab,
        page: page ?? 1,
        url: props.url,
        filters
      })
    }

    const getStatsData = (data, clear = false) => {
      if (clear == true) clearFilters(false)
      getStats(data)
    }

    const ResetActiveFilters = () => {
      const widget = !!widgets.value?.find(
        widget => widget.id == filters.activeWidget.id
      )
      const office = !!offices.value?.find(
        office => office.id == filters.activeContact.id
      )
      const department = !!departments.value?.find(
        department => department.id == filters.activeDepartment.id
      )

      if (!widgets.value?.length || !widget) {
        setActiveResourceFilter({
          type: 'activeWidget',
          id: 'filters.widget',
          resource: { id: 0, widget_id: 0, title: '' }
        })
      }

      if (!offices.value?.length || !office) {
        setActiveResourceFilter({
          type: 'activeContact',
          id: 'filters.office',
          resource: { id: 0, title: '' }
        })
      }

      if (!departments.value?.length || !department) {
        setActiveResourceFilter({
          type: 'activeDepartment',
          id: 'filters.department',
          resource: { id: 0, title: '' }
        })
      }

      if (!fullnames.value?.length) filters.fullname = ''

      if (!emails.value?.length) filters.email = ''

      if (!messageSenders.value?.length) filters.messageSender = ''
    }

    watch(
      filters,
      value => {
        if (route.query.tab) {
          ResetActiveFilters()

          router.replace({
            name: 'report-stats',
            query: {
              tab: tab.value,
              page: 1
            }
          })
        }

        // temporary added
        // getStatsData({ resource: tab.value, page: 1, url: props.url, filters }, false);
        if (isMounted.value)
          getStatsData(
            { resource: tab.value, page: 1, url: props.url, filters },
            false
          )
      },
      {
        deep: true
      }
    )

    onMounted(async () => {
      clearFilters(false)
      await getStats({
        resource: tab.value,
        page: route.query.page ?? 1,
        url: props.url,
        filters
      })
      getContacts()
    })

    return {
      columns,
      stats,
      isMounted,
      tab,
      getHeading,
      meta,
      loading,
      filters,
      fetchMore,
      getStatsData,
      clearFilters,
      toggleFilters,
      showFilters,
      reportsData,
      isNotTab
    }
  },

  watch: {
    '$route.query.tab' (value) {
      this.filters.refresh = stubFalse
      // this.getStatsData(
      //   { resource: value, page: 1, url: this.url, filters: this.filters },
      //   true
      // );
    }
  }
}
</script>

<style>
.dp-custom-input {
  border: none;
  width: 200px;
}
</style>
<style lang="scss" scoped>
.filters {
  display: grid;
  grid-template-columns: 95% 5%;
  align-items: center;
}
</style>
