<template>
  <tbody class="stats-table-body" v-if="data.length">
    <tr v-for="stats in data" :key="stats.id">
      <td :title="stats.widget">
        {{ slice(stats.widget, 20) }}
      </td>
      <OfficeAndDepartment :stats="stats" />
      <td>{{ stats.location }}</td>
      <td>{{ moment(stats.callstart).format('LLL') }}</td>
      <td>{{ calculateDuration(stats.callstart, stats.callstop) }}</td>
    </tr>
  </tbody>
</template>

<script setup>
import moment from 'moment'
import useHelpers from '@/composables/useHelpers'
import OfficeAndDepartment from './OfficeAndDepartment'

defineProps({
  data: {
    type: [Array, Object],
    required: true
  }
})

const { slice } = useHelpers()

const calculateDuration = (startDate, endDate) => {
  if (startDate && endDate) {
    const a = moment(endDate)
    const b = moment(startDate)
    const seconds = a.diff(b, 'seconds')

    return moment().startOf('day').seconds(seconds).format('H:mm:ss')
  }
}
</script>

<style lang="scss" scoped>
td {
  vertical-align: top;
}
</style>
